// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

import {IProductEnvironment} from "./FacilitiesEvaluationEnvironment";
import {EProductType} from "../javascript.lib.mojo-base/model/ProductType";
import {FacilityAccessibilityProduct4} from "../product.facility/model/FacilityAccessibilityProduct4";

export const environment: IProductEnvironment = {

  name: 'An Post: DSU',
  filename: 'environment.dsu-80798384.ts',

  isDevelopment: false,
  isProduction: true,
  isTest: false,

  authenticationConfig: {

    emailLink: false,
    textMessage: true,
    usernamePassword: true
  },

  firebaseConfig: {
    databaseURL: "https://facilities-80798384-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "facilities-80798384",
    storageBucket: "facilities-80798384.appspot.com",

    apiKey: "AIzaSyBhOXbNFzb0KoJ8VaBkasiKSpuxXGUxVjs",
    authDomain: "facilities-80798384.firebaseapp.com",
    messagingSenderId: "590581802806",
    appId: "1:590581802806:web:234f1f0700ed81f8ecb597"
  },

  // curl -iLS https://us-central1-$_TARGET_ENV.cloudfunctions.net/public/functions/info
  cloudFunctionsUrl: 'https://us-central1-facilities-80798384.cloudfunctions.net',

  nocoDbConfig: {
    // httpServer: 'http://localhost:8081',
    httpServer: 'https://next.mobilitymojo-spot.com',
    user: 'api-viewer@mobilitymojo.com',
    password: 'IxqWxN;A<s2b=(H',
    authorization: 'Basic bW9iaWxpdHk6bW9qbw==',
    projectTitle: 'An Post - DSU - 2024',
  },

  // nocoDbConfig: {
  //   // httpServer: 'http://localhost:8080',
  //   // httpServer: 'http://spot.local:8080',
  //   httpServer: 'https://mobilitymojo-spot.com',
  //   user: 'test@mobilitymojo.com',
  //   password: 'test@mobilitymojo.com',
  //   authorization: 'Basic bW9iaWxpdHk6bW9qbw==',
  //   projectTitle: 'An Post - DSU - 2024.Next',
  // },

  productConfig: {

    firebaseAnswersRoot: '/answers-x/an-post-dsu',
    // product:  ProductHelper.cloneProduct( new FacilityAccessibilityProduct(), true )
    product:  FacilityAccessibilityProduct4.INSTANCE,
    productType: EProductType.anPostDsu,
    canCommentOnClusters: true,
    canEditClusters: false,
    clusterSupport: true,

  },




};
