


export class BrowserAppEvaluationToolInfo {

  public version = '2024.6.7.2';

  constructor() {
  }

}
